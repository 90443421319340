import { CookieKeys } from "@travellocal/ui";
import { isLocale } from "@travellocal/utils";
import { i18n } from "next-i18next";
import { GetServerSidePropsContext, GetStaticPropsContext } from "next";
import nookies from "nookies";
import { getGlobalContent, getSubscribe } from "utils/api/cms";
import { PageProps } from "..";
import { getCountriesWithPublishedTrips } from "../../utils/api/site2";

interface SocialMediaProfile {
  label: string;
  platform: string;
  url: string;
}

function isSocialMediaProfile(sm: unknown): sm is SocialMediaProfile {
  return typeof sm === `object` && `label` in sm && `platform` in sm && `url` in sm;
}

export type InitialPageProps = Pick<
  PageProps,
  "countries" | "initialAppState" | "alternatePages" | "socialMedia" | "subscribeResponse"
>;

export const getServerPageContent = async (context: GetServerSidePropsContext | null) => {
  const cookies = nookies.get(context) as Record<CookieKeys, string>;
  return getInitialPageContent(context.locale, cookies);
};

export const getStaticPageContent = async (context: GetStaticPropsContext | null) => {
  return getInitialPageContent(context.locale);
};

const cache = {};

const getInitialPageContent = async (
  pageLocale: string,
  cookies: Partial<Record<CookieKeys, string>> = {}
): Promise<InitialPageProps> => {
  if (!isLocale(pageLocale)) {
    return {
      countries: [],
      initialAppState: {},
      socialMedia: [],
      subscribeResponse: {
        subscribeText: null,
        subscribeBodyText: null,
      },
    };
  }

  const subscribeResponse = await getSubscribe([pageLocale]);

  if (process.env.NODE_ENV === "development") {
    // Keep resources up to date server-side
    await i18n?.reloadResources();
  }

  const cacheKey = pageLocale;
  const cacheDuration = 43200000; // 12 hours

  // Check if data is in cache and still valid
  let countries = [];
  if (cache[cacheKey] && Date.now() - cache[cacheKey].timestamp < cacheDuration) {
    countries = cache[cacheKey].data;
  } else {
    countries = await getCountriesWithPublishedTrips(pageLocale);
    cache[cacheKey] = {
      data: countries,
      timestamp: Date.now(),
    };
  }

  const globalContent = await getGlobalContent([pageLocale]);

  const socialMedia = (globalContent.nodes[0]?.globalContent.socialmedia || []).filter(
    isSocialMediaProfile
  );

  return {
    countries: countries ?? [],
    initialAppState: {
      locale: pageLocale,
      cookies,
    },
    socialMedia,
    subscribeResponse,
  };
};

export default getInitialPageContent;
